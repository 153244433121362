//Write a react component AudioBook which lets the user upload their audio (an mp3 file) to localhost:5000/api/audio. The request is sent along with a session_id whose value is stored in sessionId in localStorage.
	//
	//	Display Generating while the request gets processed then render the audio file recieved from the backend. The audiofile is supposed to be rendered, no links will be recieved.
	// We'll start by importing the necessary dependencies and hooks
import React, { useState } from 'react';
import NextButton from './NextButton';
import { useNavigate, Link } from 'react-router-dom';




// Then we'll create the AudioBook component
const AudioBook = () => {



	const history = useNavigate();
	const handleNext = () => {
		history("/sales-letter");
	};

	// We'll use a state to store the uploaded audio file
	const [audioFile, setAudioFile] = useState(null);

	// We'll use another state to track whether the request is being processed
	const [isUploading, setIsUploading] = useState(false);

	// We'll get the session_id from localStorage
	const sessionId = localStorage.getItem('sessionId');

	// We'll create a function to handle the file upload
	const handleUpload = (event) => {
		// Get the selected file
		const file = event.target.files[0];

		// Create a FormData object to send the file and session_id
		const jsonData = JSON.stringify({ session_id: sessionId });
		const formData = new FormData();
		formData.append('json_data', jsonData);
		formData.append('file', file);

		// Set isUploading to true
		setIsUploading(true);
		const delay = ms => new Promise(res => setTimeout(res, ms));
		delay(3000);

		// Send the request to localhost:5000/api/audio
		fetch('https://glowing-invention-backend-755833753464.us-east1.run.app/api/audio_book', {
			method: 'POST',
			body: formData,
		})
			.then((response) => response.blob())
			.then((audioBlob) => {
				// Set the uploaded audio file
				setAudioFile(audioBlob);

				// Set isUploading to false
				setIsUploading(false);
			})
			.catch((error) => {
				// Handle the error
				console.error(error);

				// Set isUploading to false
				setIsUploading(false);
			});
	};

	// We'll render a file input to select the audio file
	return (

		<>

		<button data-drawer-target="logo-sidebar" data-drawer-toggle="logo-sidebar" aria-controls="logo-sidebar" type="button" class="inline-flex items-center p-2 mt-2 ms-3 text-sm text-gray-500 rounded-lg sm:hidden hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-gray-200 dark:text-gray-400 dark:hover:bg-gray-700 dark:focus:ring-gray-600">
		<span class="sr-only">Open sidebar</span>
		<svg class="w-6 h-6" aria-hidden="true" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
		<path clip-rule="evenodd" fill-rule="evenodd" d="M2 4.75A.75.75 0 012.75 4h14.5a.75.75 0 010 1.5H2.75A.75.75 0 012 4.75zm0 10.5a.75.75 0 01.75-.75h7.5a.75.75 0 010 1.5h-7.5a.75.75 0 01-.75-.75zM2 10a.75.75 0 01.75-.75h14.5a.75.75 0 010 1.5H2.75A.75.75 0 012 10z"></path>
		</svg>
		</button>

		<aside id="logo-sidebar" class="fixed top-0 left-0 z-40 w-64 h-screen transition-transform -translate-x-full sm:translate-x-0" aria-label="Sidebar">
		<div class="h-full px-3 py-4 overflow-y-auto bg-gray-50 dark:bg-gray-800">
		<a href="https://rediminds.com/" class="flex items-center ps-2.5 mb-5">
		<img src="https://rediminds.com/wp-content/uploads/2021/10/logo-1.jpg" class="h-6 me-3 sm:h-7" alt="Flowbite Logo" />
		<span class="self-center text-xl font-semibold whitespace-nowrap dark:text-white">Rediminds</span>
		</a>
		<ul class="space-y-2 font-medium">
		<li>
		<Link to="/" class="flex items-center p-2 text-blue-600 rounded-lg dark:text-white hover:bg-gray-100 dark:hover:bg-gray-700 group">
		{/* <svg class="w-5 h-5 text-gray-500 transition duration-75 dark:text-gray-400 group-hover:text-gray-900 dark:group-hover:text-white" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 22 21">
			<path d="M16.975 11H10V4.025a1 1 0 0 0-1.066-.998 8.5 8.5 0 1 0 9.039 9.039.999.999 0 0 0-1-1.066h.002Z"/>
			<path d="M12.5 0c-.157 0-.311.01-.565.027A1 1 0 0 0 11 1.02V10h8.975a1 1 0 0 0 1-.935c.013-.188.028-.374.028-.565A8.51 8.51 0 0 0 12.5 0Z"/>
			</svg> */}
		<span class="ms-3 text-blue "><b>01</b> Start Here </span>
		</Link>
		</li>
		<li>
		<Link to="/baps" class="flex items-center p-2 text-blue-600 rounded-lg dark:text-white hover:bg-gray-100 dark:hover:bg-gray-700 group">
		{/* <svg class="flex-shrink-0 w-5 h-5 text-gray-500 transition duration-75 dark:text-gray-400 group-hover:text-gray-900 dark:group-hover:text-white" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 18 18">
			<path d="M6.143 0H1.857A1.857 1.857 0 0 0 0 1.857v4.286C0 7.169.831 8 1.857 8h4.286A1.857 1.857 0 0 0 8 6.143V1.857A1.857 1.857 0 0 0 6.143 0Zm10 0h-4.286A1.857 1.857 0 0 0 10 1.857v4.286C10 7.169 10.831 8 11.857 8h4.286A1.857 1.857 0 0 0 18 6.143V1.857A1.857 1.857 0 0 0 16.143 0Zm-10 10H1.857A1.857 1.857 0 0 0 0 11.857v4.286C0 17.169.831 18 1.857 18h4.286A1.857 1.857 0 0 0 8 16.143v-4.286A1.857 1.857 0 0 0 6.143 10Zm10 0h-4.286A1.857 1.857 0 0 0 10 11.857v4.286c0 1.026.831 1.857 1.857 1.857h4.286A1.857 1.857 0 0 0 18 16.143v-4.286A1.857 1.857 0 0 0 16.143 10Z"/>
			</svg> */}
		<span class="flex-1 ms-3 whitespace-nowrap"><b>02</b> Problems You Can Solve </span>
		</Link>
		</li>
		<li>
		<Link to="/customer-avatars" class="flex items-center p-2 text-blue-600 rounded-lg dark:text-white hover:bg-gray-100 dark:hover:bg-gray-700 group">
		{/* <svg class="flex-shrink-0 w-5 h-5 text-gray-500 transition duration-75 dark:text-gray-400 group-hover:text-gray-900 dark:group-hover:text-white" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 20 20">
			<path d="m17.418 3.623-.018-.008a6.713 6.713 0 0 0-2.4-.569V2h1a1 1 0 1 0 0-2h-2a1 1 0 0 0-1 1v2H9.89A6.977 6.977 0 0 1 12 8v5h-2V8A5 5 0 1 0 0 8v6a1 1 0 0 0 1 1h8v4a1 1 0 0 0 1 1h2a1 1 0 0 0 1-1v-4h6a1 1 0 0 0 1-1V8a5 5 0 0 0-2.582-4.377ZM6 12H4a1 1 0 0 1 0-2h2a1 1 0 0 1 0 2Z"/>
			</svg> */}
		<span class="flex-1 ms-3 whitespace-nowrap"><b>03</b> Ideal Clients To Target</span>
		</Link>
		</li>
		<li>
		<Link to="/lead-magnets" class="flex items-center p-2 text-blue-600 rounded-lg dark:text-white hover:bg-gray-100 dark:hover:bg-gray-700 group">
		{/* <svg class="flex-shrink-0 w-5 h-5 text-gray-500 transition duration-75 dark:text-gray-400 group-hover:text-gray-900 dark:group-hover:text-white" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 20 18">
			<path d="M14 2a3.963 3.963 0 0 0-1.4.267 6.439 6.439 0 0 1-1.331 6.638A4 4 0 1 0 14 2Zm1 9h-1.264A6.957 6.957 0 0 1 15 15v2a2.97 2.97 0 0 1-.184 1H19a1 1 0 0 0 1-1v-1a5.006 5.006 0 0 0-5-5ZM6.5 9a4.5 4.5 0 1 0 0-9 4.5 4.5 0 0 0 0 9ZM8 10H5a5.006 5.006 0 0 0-5 5v2a1 1 0 0 0 1 1h11a1 1 0 0 0 1-1v-2a5.006 5.006 0 0 0-5-5Z"/>
			</svg> */}
		<span class="flex-1 ms-3 whitespace-nowrap"><b>04</b>Lead Magnet Ideas <br/>to Attract Ideal Clients</span>
		</Link>
		</li>
		<li>
		<Link to="/ideal-book-recommendation" class="flex items-center p-2 text-blue-600 rounded-lg dark:text-white hover:bg-gray-100 dark:hover:bg-gray-700 group">
		{/* <svg class="flex-shrink-0 w-5 h-5 text-gray-500 transition duration-75 dark:text-gray-400 group-hover:text-gray-900 dark:group-hover:text-white" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 18 20">
			<path d="M17 5.923A1 1 0 0 0 16 5h-3V4a4 4 0 1 0-8 0v1H2a1 1 0 0 0-1 .923L.086 17.846A2 2 0 0 0 2.08 20h13.84a2 2 0 0 0 1.994-2.153L17 5.923ZM7 9a1 1 0 0 1-2 0V7h2v2Zm0-5a2 2 0 1 1 4 0v1H7V4Zm6 5a1 1 0 1 1-2 0V7h2v2Z"/>
			</svg> */} 
		<span class="flex-1 ms-3 whitespace-nowrap"><b>05</b> Proposal for <br/>Your Authority Book</span>
		</Link>
		</li>

		<li>
		<Link to="/ideal-book" class="flex items-center p-2 text-blue-600 rounded-lg dark:text-white hover:bg-gray-100 dark:hover:bg-gray-700 group">
		{/*  {/* <svg class="flex-shrink-0 w-5 h-5 text-gray-500 transition duration-75 dark:text-gray-400 group-hover:text-gray-900 dark:group-hover:text-white" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 18 20">
			<path d="M17 5.923A1 1 0 0 0 16 5h-3V4a4 4 0 1 0-8 0v1H2a1 1 0 0 0-1 .923L.086 17.846A2 2 0 0 0 2.08 20h13.84a2 2 0 0 0 1.994-2.153L17 5.923ZM7 9a1 1 0 0 1-2 0V7h2v2Zm0-5a2 2 0 1 1 4 0v1H7V4Zm6 5a1 1 0 1 1-2 0V7h2v2Z"/>
			</svg> */}
			<span class="flex-1 ms-3 whitespace-nowrap"><b>06</b> Your Authority book</span>
			</Link>
			</li>

			<li>
			<Link to="audio-book" class="flex items-center p-2 text-blue-600 rounded-lg dark:text-white hover:bg-gray-100 dark:hover:bg-gray-700 group">
			{/* <svg class="flex-shrink-0 w-5 h-5 text-gray-500 transition duration-75 dark:text-gray-400 group-hover:text-gray-900 dark:group-hover:text-white" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 18 20">
				<path d="M17 5.923A1 1 0 0 0 16 5h-3V4a4 4 0 1 0-8 0v1H2a1 1 0 0 0-1 .923L.086 17.846A2 2 0 0 0 2.08 20h13.84a2 2 0 0 0 1.994-2.153L17 5.923ZM7 9a1 1 0 0 1-2 0V7h2v2Zm0-5a2 2 0 1 1 4 0v1H7V4Zm6 5a1 1 0 1 1-2 0V7h2v2Z"/>
				</svg> */}
			<span class="flex-1 ms-3 whitespace-nowrap"><b>07</b> Your Authority Audiobook</span>
			</Link>
			</li>


			<li>
			<div to="/sales-letter" class="flex items-center p-2 text-gray-900 rounded-lg dark:text-white hover:bg-gray-100 dark:hover:bg-gray-700 group">
			{/* <svg class="flex-shrink-0 w-5 h-5 text-gray-500 transition duration-75 dark:text-gray-400 group-hover:text-gray-900 dark:group-hover:text-white" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 18 20">
				<path d="M17 5.923A1 1 0 0 0 16 5h-3V4a4 4 0 1 0-8 0v1H2a1 1 0 0 0-1 .923L.086 17.846A2 2 0 0 0 2.08 20h13.84a2 2 0 0 0 1.994-2.153L17 5.923ZM7 9a1 1 0 0 1-2 0V7h2v2Zm0-5a2 2 0 1 1 4 0v1H7V4Zm6 5a1 1 0 1 1-2 0V7h2v2Z"/>
				</svg> */}
			<span class="flex-1 ms-3 whitespace-nowrap"><b>08</b> Copywriting To <br/> Sell Your Book </span>
			</div>
			</li>
			</ul>


			</div>
			</aside>

			<div class="p-4 sm:ml-64">
			<div class="p-4 border-2 border-gray-200 border-dashed rounded-lg dark:border-gray-700">
			<div class="  mb-4">



			<div>

			<h1 className="mb-4 text-4xl font-extrabold leading-none tracking-tight text-gray-900 md:text-5xl lg:text-6xl dark:text-white">
			Your <mark className="px-2 text-white bg-blue-600 rounded dark:bg-blue-500">Audio-Book</mark> 
			</h1>

			<div class="flex items-center justify-center w-full">
			<label for="dropzone-file" class="flex flex-col items-center justify-center w-full h-64 border-2 border-gray-300 border-dashed rounded-lg cursor-pointer bg-gray-50 dark:hover:bg-gray-800 dark:bg-gray-700 hover:bg-gray-100 dark:border-gray-600 dark:hover:border-gray-500 dark:hover:bg-gray-600">Upload your audio sample
			<div class="flex flex-col items-center justify-center pt-5 pb-6">
			<svg class="w-8 h-8 mb-4 text-gray-500 dark:text-gray-400" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 20 16">
			<path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M13 13h3a3 3 0 0 0 0-6h-.025A5.56 5.56 0 0 0 16 6.5 5.5 5.5 0 0 0 5.207 5.021C5.137 5.017 5.071 5 5 5a4 4 0 0 0 0 8h2.167M10 15V6m0 0L8 8m2-2 2 2"/>
			</svg>
			<p class="mb-2 text-sm text-gray-500 dark:text-gray-400"><span class="font-semibold">Click to upload</span> or drag and drop</p>
			<p class="text-xs text-gray-500 dark:text-gray-400"> MP3</p>
			</div>
			<input id="dropzone-file" onChange={handleUpload} type="file" class="hidden" />
			</label>
			</div>

			{isUploading ? (
				<div>
				<div className="absolute animate-spin rounded-full h-32 w-32 border-t-4 border-b-4 border-blue-500"></div>

				<p>Generating audiobook 🎙️</p>
				</div>
			) : (
				// If audioFile is not null, render the audio file
				audioFile ? (
					<div class="bg-gray-100 p-4 flex justify-center  h-screen">
					<audio controls>
					<source src={URL.createObjectURL(audioFile)} type="audio/mp3" />
					</audio>
					</div>
				) : (
					// If audioFile is null, display nothing
					null
				)
			)}
			{/* <NextButton handleNext={handleNext} /> */}






			</div>
			<NextButton handleNext={handleNext} />
			</div>
			</div>
			</div>
			</>
		);
		};

		export default AudioBook;
