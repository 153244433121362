import React from 'react';
import RemoveMarkdown from 'remove-markdown';
import '../App.css';

const AvatarCard = ({ title, description = [] }) => {
  return (
    <div className="mobile-prest">
      <div className="relative flex flex-col px-4 pt-24 pb-12 font-sans text-gray-700 bg-blue-200 sm:px-6 lg:px-8 min-h-screen">
        <div className="flex-1 space-y-8 text-center">
          {description.slice(0, 5).map((desc, index) => {
            // Strip markdown to get plain text
            const plainText = RemoveMarkdown(desc);
            return (
              <div
                key={index}
                className="sticky top-[calc(1rem_*_1)] w-full max-w-xl px-8 py-12 mx-auto space-y-4 bg-white border rounded-lg shadow-lg"
              >
                <h2 className="text-2xl font-bold leading-none text-gray-900 space-y-1">
                  <span className={`block text-sm ${getColor(index)}`}>
                    Customer {index + 1}
                  </span>
                </h2>
                {/* Render the plain text after removing markdown */}
                <p>{plainText}</p>
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
};

// Helper function for color
const getColor = (index) => {
  const colors = ['text-blue-700', 'text-red-700', 'text-yellow-700', 'text-purple-700', 'text-green-700'];
  return colors[index % colors.length];
};

export default AvatarCard;
